.container {
    position: relative;
    max-width: 500px;
    width: 100%;
    background: #FCEDDA;
    padding: 25px;
    border-radius: 8px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    align-items: center;
    justify-content: center;
  }
  
  .container header {
    font-size: 1.2rem;
    color: #000;
    font-weight: 600;
    text-align: center;
  }
  
  .container .form {
    margin-right: 50px;
  }
  
  .form .input-box {
    width: 100%;
    margin: 15px;
  }
  
  .input-box label {
    color: #000;
  }
  
  .form :where(.input-box input, .select-box) {
    position: relative;
    height: 35px;
    width: 100%;
    outline: none;
    font-size: 1rem;
    color: #808080;
    margin-top: 5px;
    border: 1px solid #EE4E34;
    border-radius: 6px;
    padding: 0 15px;
    background: #FCEDDA;
  }
  
  .input-box input:focus {
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
  }
  
  .form .column {
    display: flex;
    column-gap: 15px;
  }
  
  .form .gender-box {
    margin-top: 10px;
  }
  
  .form :where(.gender-option, .gender) {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  
  .form .gender {
    column-gap: 5px;
  }
  
  .gender input {
    accent-color: #EE4E34;
  }
  
  .form :where(.gender input, .gender label) {
    cursor: pointer;
  }
  
  .gender label {
    color: #000;
  }
  
  .address :where(input, .select-box) {
    margin-top: 10px;
  }
  
  .select-box select {
    height: 100%;
    width: 100%;
    outline: none;
    border: none;
    color: #808080;
    font-size: 1rem;
    background: #FCEDDA;
  }
  
  /* .form button {
    height: 40px;
    width: 100%;
    color: #000;
    font-size: 1rem;
    font-weight: 400;
    margin-top: 15px;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: all 0.2s ease;
    background: #EE4E34;
  } */
  
  /* .form button:hover {
    background: #EE3E34;
  } */

  .error-highlight {
    border: 1px solid #FF0000; /* Red border */
    box-shadow: 0 0 5px #FF0000; /* Red box shadow */
  }

  .section-heading {
    font-size: 1.1rem; /* Adjust the font size as needed */
    font-weight: bold;
    margin-top: 20px; /* Adjust the top margin as needed */
    margin-bottom: 0px; /* Adjust the bottom margin as needed */
    margin-left: 10px;
    color: #333; /* Adjust the color as needed */
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .error-message {
    margin-left: 600px;
  }