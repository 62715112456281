/* NX = not expanded */

.side-nav-container {
	background: linear-gradient(to right, #202c3d, #103059);
	width: 225px;
	height: 190vh;
	position: fixed;
	left: 0;
	top: 0;
	color: white;
	transition: 0.4s;
	z-index: 1;
	overflow: hidden;
}
.side-nav-container-NX {
	width: 60px;
	overflow: hidden;
}

.nav-upper,
.nav-heading,
.nav-menu
 {
	/* border: 5px solid white; */
	display: grid;
}



.nav-heading {
	grid-template-columns: 2fr 1fr;
	grid-template-rows: 1fr;
	height: 0px;
	
}

.nav-brand {
	display: flex;
	color: white;
	margin-top: 7px;
	margin-left: 40px;
}
/* .nav-brand img {
	width: 40px;
	padding: 0 10px;
} */

.hamburger {
	background-color: white;
	border: none;
	cursor: pointer;
	margin-top: 10px;    
	padding: 5px;
	width: 40px;
	
}

.hamburger span {
	display: block;
	margin-top: 4px;
    margin-bottom: 4px;
    margin-left: 2px;
	background-color:#103059 ;
	border-radius: 6px;
	height: 3px;
	width: 25px;

	transition: 0.4s;
}

.hamburger-in {
	margin-left: 20px; /* Adjust the left margin for the expanded state */
  }
  
.hamburger:hover span {
	background-color: white;
}

.hamburger-in:hover span:nth-child(1) {
	width: 25px;
	transform: translateY(4px) rotate(-25deg);
}
.hamburger-in:hover span:nth-child(2) {
	width: 25px;
}
.hamburger-in:hover span:nth-child(3) {
	width: 25px;
	transform: translateY(-4px) rotate(25deg);
}
/* ///////////////////// */
/* ///////////////////// */
/* ///////////////////// */
/* ///////////////////// */
.hamburger-out {
	margin-left: 10px;
}
.hamburger-out:hover span:nth-child(1) {
	width: 25px;
	transform: translate(14px, 4px) rotate(-155deg);
}
.hamburger-out:hover span:nth-child(2) {
	width: 25px;
}
.hamburger-out:hover span:nth-child(3) {
	width: 25px;
	transform: translate(14px, -4px) rotate(155deg);
}

.nav-menu {
	grid-template-rows: repeat(7, 1fr);
	margin-top: 50px;
	
}

/* .side-nav-container-NX .nav-menu {
	display: none;
  } */

  .sidebarlist {
	height: 90px;
	display: flex;
	flex-direction: column;
	text-decoration: none;
	text-transform: uppercase;
	margin-top: 0px;
	margin-right: -120px;
	border-radius: 10px;
	cursor: pointer;
	list-style-type: none;
  }

  .sidebarlist > li {
	display: flex;
	align-items: center;
	
	margin-bottom: 30px; /* Align items vertically in the center */
  }
  

  .side-nav-container-NX .sidebarlist {
	margin-top: 30px;
	margin-left: -35px;
  }
  
  .side-nav-container-NX .sidebarlist div#icon {
	margin-bottom: 0px;  /* Adjust the margin to create space between icons */
	margin-right: 80px;
}
  .sidebarlist div#icon {
	margin-bottom: 0px;
	margin-left: 15px;
	
  }

  


/* .sidebarlist-NX {
	margin: auto;
} */
.sidebarlist:hover {
	background-color: var(--primary);
}
/* #icon img {
	width: 30px;
	padding: 0 20px;
} */


  
/* .nav-footer {
	width: 100%;
	height: 87px;
	position: absolute;
	bottom: 0;
	grid-template-rows: 1fr;
	grid-template-columns: 2fr 1fr;
} */

/* .nav-details {
	display: flex;
} */
/* .nav-details img {
	width: 50px;
	padding: 0 20px;
} */

/* .nav-footer-user-name {
	font-size: 18px;
	font-weight: 900;
}

.nav-footer-user-position {
	margin-top: -15px;
	color: var(--gray);
}
.logout-icon {
	width: 30px;
	margin: auto;
	border-radius: 90px;
	padding: 20px;
	margin-left: 5px;
}
.logout-icon:hover {
	background-color: var(--primary);
} */