
.p-datatable {
    border-collapse: collapse;
    margin: 25px 0;
    font-size: 10px;
    border-radius: 5px 5px 0 0;
}

.p-datatable thead th {
    background-color: #009879;
    color: #ffffff;
    text-align: left;
    font-weight: bold;
   
}

.p-datatable th,
.p-datatable td {
    padding: 10px 12px;
    
}

.p-datatable td {
    border-bottom: 1px solid #dddddd;
}

.p-datatable tbody tr:nth-of-type(even) {
    background-color: #f3f3f3;
}

.p-datatable tbody tr:last-of-type {
    border-bottom: 2px solid #009879;
}

.p-datatable .p-datatable-thead tr:nth-child(1),
.p-datatable .p-datatable-thead td:nth-child(1),
.p-datatable .p-datatable-tbody tbody:nth-child(1) {
    width: 50px;
}

.sampleRow{
    width: 50px;
}
.p-datatable .p-datatable-thead th:nth-child(2),
.p-datatable .p-datatable-tbody td:nth-child(2){
    width: 50px; /* Adjust the width as needed */
    white-space: nowrap; /* Prevent text wrapping */
    overflow: hidden; /* Hide any overflowing text */
    text-overflow: ellipsis;
}


.over-all{
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 13px;
    height: 35px;
    border-radius: 10px;
    padding-left: 10px;
    color: white;
    border: none;
    width: 150px;
    margin-top: 0px;
    text-align: center;
    background-color: #009879;
    /* background: linear-gradient(to right, rgb(137, 235, 186), rgb(39, 148, 93)); */
   
}
  
  .over-all option {
    background-color: #fff;
    color: #000000;
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    text-transform: capitalize;
  }

.p-datatable-thead th {
    padding-left: 5px;
}


.p-column-filter-menu-button {
    position: relative;
    margin-left: 10px;
    top: 25px; 
    right: 78px;
    width: 55px;
    height: 18px;
    padding-bottom: 1px;
    border-color: transparent;
    border-radius: 8px;
}

/* Adjust the position of filter menu button for EntryUser column */
.entry-user-column .p-column-filter-menu-button {
    position: relative;
    left: -100px; /* Adjust this value as needed */
}


.p-column-filter-menu-button:hover {
    background: linear-gradient(90deg, #22c1c3 0%,  #92be77 50% ,#fdbb2d 100%); /* Gradient with three colors */
    border-color: transparent;
    background-size: 300% 100%; /* Make the gradient wider to cover the entire hover duration */
    animation: gradientChange 2s infinite; /* Apply animation with a 2s duration */
}

@keyframes gradientChange {
    0% { background-position: 0%; } /* Start with the first color */
    50% { background-position: 100%; } /* Transition to the second color at 50% of the animation */
    100% { background-position: 200%; } /* Transition to the third color at 100% of the animation */
}

.p-datatable th .p-column-title {
    margin-bottom: 15px;
}

.p-icon.p-sortable-column-icon {
    position: relative;
    padding: 2px;
    bottom: 6px;
}

.p-column-filter-menu-button .p-icon {
    color: #ffffff86;
}

.p-column-filter-buttonbar .p-button-sm{
    background-color: #ffffff;
    border-color: transparent;
}

/* .p-column-filter-buttonbar {
    background-color: #009879;
} */

.p-column-filter-overlay {
        background-color: rgba(89, 252, 219, 0.308); /* Adjust transparency as needed */
        border-radius: 10px; /* Rounded corners for a softer look */
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);  /* Add padding for space inside the overlay */
        margin-left: 14px;
        margin-top: 8px;
        
}

.p-column-filter-operator {
         background-color: rgba(89, 252, 219, 0.123); /* Adjust transparency as needed */
         border-radius: 10px; /* Rounded corners for a softer look */
}

.p-column-filter-operator-dropdown{
    background-color: rgba(255, 255, 255, 0.568);
}

.p-column-filter-matchmode-dropdown{
    background-color: rgba(255, 255, 255, 0.568);
}

.p-button-label {
    color: #000000b6;
}

.p-button.p-button-sm{
    background-color: #00987a94;
    border-color: transparent;
}

.p-dropdown-item.p-highlight{
    background-color: #3effd88c;
    color: #009879;
}

.p-column-filter-add-button .p-icon{
    color: #000000b6;
    margin-right: 6px;
}

/* Targeting the paginator button */
.p-paginator-page.p-paginator-element.p-link {
    background-color: transparent; /* Change the background color */
    color: black;              /* Change the text color */
    border-radius: 20%;        /* Make the button round */
    width: 42px;               /* Set a fixed width */
    height: 42px;              /* Set a fixed height */
    border: none;              /* Remove border */
    justify-content: center;   /* Center the text horizontally */
    transition: background-color 0.3s; /* Smooth transition on hover */
}

.p-paginator-first.p-paginator-element.p-link {
    background-color: transparent; /* Change the background color */
    color: black;              /* Change the text color */
    border-radius: 20%;        /* Make the button round */
    width: 42px;               /* Set a fixed width */
    height: 42px;              /* Set a fixed height */
    border: none;              /* Remove border */
    justify-content: center;   /* Center the text horizontally */
    transition: background-color 0.3s; /* Smooth transition on hover */
}

.p-paginator-prev.p-paginator-element.p-link {
    background-color: transparent; /* Change the background color */
    color: black;              /* Change the text color */
    border-radius: 20%;        /* Make the button round */
    width: 42px;               /* Set a fixed width */
    height: 42px;              /* Set a fixed height */
    border: none;              /* Remove border */
    justify-content: center;   /* Center the text horizontally */
    transition: background-color 0.3s; /* Smooth transition on hover */
}

.p-paginator-next.p-paginator-element.p-link {
    background-color: transparent; /* Change the background color */
    color: black;              /* Change the text color */
    border-radius: 20%;        /* Make the button round */
    width: 42px;               /* Set a fixed width */
    height: 42px;              /* Set a fixed height */
    border: none;              /* Remove border */
    justify-content: center;   /* Center the text horizontally */
    transition: background-color 0.3s; /* Smooth transition on hover */
}

.p-paginator-last.p-paginator-element.p-link {
    background-color: transparent; /* Change the background color */
    color: black;              /* Change the text color */
    border-radius: 20%;        /* Make the button round */
    width: 42px;               /* Set a fixed width */
    height: 42px;              /* Set a fixed height */
    border: none;              /* Remove border */
    justify-content: center;   /* Center the text horizontally */
    transition: background-color 0.3s; /* Smooth transition on hover */
}

.p-paginator-page.p-paginator-element.p-link:hover {
    background-color: #b3b5b48c; /* Change the background color on hover */
    border: none;
}

/* If you need to style the ripple effect */
.p-link {
    background-color: rgba(255, 255, 255, 0.3); /* Adjust ripple color */
}

/* Optional: Style the button when it's active or focused */
.p-paginator-page.p-paginator-element.p-link:focus,
.p-paginator-page.p-paginator-element.p-link[aria-current="page"] {
    outline: none;
    /* box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.5); Add a focus shadow */
}

.p-paginator-page.p-paginator-element.p-link.p-highlight {
    background-color: #3effd88c; /* Change the background color */
    color: #12342d;              /* Change the text color */
    border-radius: 5px;        /* Add some rounding to the corners */
    padding: 5px 10px;         /* Add padding */
    transition: background-color 0.3s, color 0.3s; /* Smooth transition on hover */
    border: none;
}

.p-paginator-page.p-paginator-element.p-link.p-highlight:hover {
    background-color: #3effd88c; /* Darker background color on hover */
    color: #12342d;            /* Slightly lighter text color on hover */
    border: none;
}



  .filter {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;       /* Set a width */
    height: 50px;      /* Set a height */
    background-color: #33d66f; 
    border-radius: 20%; /* Makes it a circle */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Adds a subtle shadow */
    cursor: pointer; /* Changes the cursor to a pointer */
    transition: background-color 0.3s ease; /* Smooth background color transition */
    margin-left: 20px;
    
}

.p-multiselect-close {
    border: 0px;
    width: 40px;
    box-shadow: none;

}

.p-checkbox-input {
    width: 20px;
    
}

.p-multiselect-label {
    padding: 10px;
   
}

.Dropdown{
    margin-left: 10px;
    width: 200px;
    margin-top: 6px;
}

.p-multiselect-panel{
    min-width: none;
    width: 75%;
}

@media (min-width: 1025px) {
    .p-multiselect-panel {
      width: 25%;
    }
  }


