/** @type {import('tailwindcss').Config} */
/* @type {import('tailwindcss').Config} */
/* @impo rt"tailwindcss/base"; */
@import "tailwindcss/components";
@import "tailwindcss/utilities"; 
@import url('https://fonts.googleapis.com/css2?family=Cormorant:wght@400;700&display=swap');


.main{
 
    /* background-color: rgba(232, 232, 232, 0.986);  */
    background-image: url('../Images/bg13.jpg'); 
     background-size:cover;
    
     background-position:right;     
    
}



 


