@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');
html, body, #root {
    font: 0.9rem sans-serif;
    background: #fff;
    color: rgb(3, 3, 3);
    height: 100vh;
    padding: 0;
    width: 100vw;
    margin: 0;
}

/* Style the input element */
input {
    padding: 10px;
    border-radius: 10px;
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    border: 2px solid #2ea93f;
    width: 220px;
}

textarea {
  padding: 10px;
  border-radius: 10px;
  font-size: 16px;
  font-family: 'Poppins', sans-serif;
  border: 2px solid #2ea93f;
  width: 220px;
}
.Signup-Input {
  padding: 10px;
  margin-top: 10px;
}
/* Style the placeholder text */
::placeholder {
  color: #aaa;
}

/* Style the input when it has focus */
input:focus {
  outline: none;
  border-color: #7fad39;
  box-shadow: 0 0 5px #7fad39;
}

/* Style the input when it's disabled */
input:disabled {
  opacity: 0.5;
}

/* Style the input when it has an error */
input.error {
  border-color: #d93025;
  box-shadow: 0 0 5px #d93025;
}

/* Style the input when it's valid */
input.valid {
  border-color: #1db954;
  box-shadow: 0 0 5px #1db954;
}

input[name="Physical-Activity-InputBox"] {
  width: 150px;
  padding: 10px;
}

.Update-InputBox {
  margin: 10px;
  align-self: center;
  text-align: center;
  justify-self: center;
  width: 300px;
  font-size: 12px;
  resize: none;
}

/* Style the label element */
label {
  font-size: 18px;
  font-weight: bold;
}

/* Style the container element */
.container {
  width: 80%;
  margin: auto;
}

.main {
  text-align: center;
  justify-content: center;
  display: flex; 
  padding-top: 50px;
  padding-bottom: 90px;
  background-color: #e1e3eb;
}

/* Style the select element */
.select-days {
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 13px;
  height: 35px;
  border-radius: 10px;
  padding-left: 10px;
  color: white;
  border: none;
  width: 150px;
  margin-top: 0px;
  text-align: center;
  background: linear-gradient(to right, rgb(133, 189, 235), rgb(77, 164, 235));
}

/* Style the select members */
.select-member {
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 14px;
  height: 25px;
  border-radius: 10px;
  padding-left: 10px;
  color: black;
  border: none;
  width: 150px;
  margin-top: 0px;
  margin-left: -20px;
  text-align: center;
  background: transparent;

  /* Remove arrow down symbol */
/* -webkit-appearance: none;
-moz-appearance: none;
appearance: none;
background-image: none;
background-position: right center;
background-repeat: no-repeat;
padding-right: 10px; */
}

 /* Style the select gender */
 .select-gender {
  font-family: 'Poppins', sans-serif;
  font-size: 12px;
  border-radius: 10px;
  color: black;
  border: 2px solid #2ea93f;
  width: 320px;
  padding: 10px;
  height: fit-content;
  text-align: center;
  background: #fff;
  margin: 10px;
}

.overall-label {
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 13px;
  border-radius: 10px;
  padding-left: 0px;
  margin-right: 10px;
  color: white;
  border: none;
  width: 150px;
  height: 35px;
  padding-top: 10px;
  text-align: center;
  background: linear-gradient(to right, rgb(133, 189, 235), rgb(77, 164, 235));
}

/* Style the select options */
.select-days option {
  background-color: #fff;
  color: #000000;
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  text-transform: capitalize;
}

.header-label {
  font-family: Poppins, sans-serif;
  font-size: 24px;
  font-weight: bold;
  color: #000000;
  text-transform: uppercase;
  text-align: center;
  margin-top: 20px;
}

.subheader-label {
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: bold;
  color: #000000;
  text-transform: none;
  letter-spacing: 1px;
  text-align: center;
}
.normal-label {
  font-family: Poppins, sans-serif;
  font-size: 16px;
  color: #000000;
  text-transform: none;
  letter-spacing: 1px;
  margin-top: 7px;
  margin-bottom: 7px;
}

.signup-label {
  font-family: Poppins, sans-serif;
  font-size: 14px;
  color: #000000;
  text-transform: none;
  font-weight: 500;
  margin-top: 10px;
  margin-bottom: 5px;
}

.userIcon{
  height: 25px;
  width: 25px;
  position: absolute;
  padding: 14px 0 0 25px;
}

.sub-main {
  display: flex;
  justify-content: center;
  height: auto;
  width: 350px;
  box-shadow: 11px 12px 13px 12px rgb(207, 207, 207);
  padding-top: 30px;
  border-radius: 60px; 
  background-color: white;
  padding-bottom: 20px;
}

.imgs {
    padding-top: 0px;
    justify-content: center;
    display: flex;
}

.profile {
  height: 80px;
  width: 80px;
  border-radius: 130px;
} 

.waterDroplet {
  margin-right: 50px;
} 

.container-image {
  background-color: transparent; 
  border-radius: 150px;
  align-items: center;
  display: flex;
  justify-content: center;
  height: 92px;
  width: 92px;
}

.dropdowntext{
    color: red;
}

.name{
    padding-left: 60px;
    font-size: 16px;
}
.city {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 40px 8%;
    border-radius: 20px;
    background: rgba(250, 250, 250, 0.85);
    box-shadow: 10px 10px 5px 0px rgba(15, 15, 15, 0.404);
}
.second-input{
    padding-top: 10px;
}
button {
  border-radius: 25px;
  border: 2px solid #000000;
  background-color: transparent;
  box-shadow: rgb(0 0 0 / 5%) 0 0 8px;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 14px;
  transition: all .5s ease;
  width: 160px;
  height: 40px;
  margin-top: 0px;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25);
}

button:hover {
  border: 0px solid #000000;
  background-color: transparent;
}

button:active {
  letter-spacing: 0.5px;
  transform: translateY(10px);
  transition: 100ms;
}
  
button[name ='create-account-btn'] {
  background: transparent;
  color: #2ea93f;
  width: auto;
  font-weight: bold;
  cursor: pointer;
  position: relative;
  margin-top: 0px;
  border: none;
  box-shadow: none;
}

button[name ='input-submit'] {
  color: white;
  padding: 5px 5px;
  background: linear-gradient(to right, #53cc63, #2ea93f);
  border: 0px solid #2ea93f;
  font-weight: bold;
  width: 35px;
  border-radius: 5px;
  margin: 10px;
}

button[name ='logout-btn'] {
  display: flex;
  align-items: center;
  padding: 0px 0px;
  justify-content: space-evenly;
  color: white;
  font-weight: bold;
  width: 100px;
  height: 30px;
  border: none;
  margin-top: 5px;
  border-radius:40px;
  right: 0px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition-duration: .3s;
  margin-right: 20px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.199);
  background-color: rgb(255, 65, 65);
}
.login-button{
  background: linear-gradient(to right, #53eb53, #2ea93f);
  margin-top: 20px;
  cursor: pointer;
  position: relative;
  border: none;
  color: white;
}

.link{
    font-size: 25px;
    font-weight: 400;
}
a{
    color: blue;
}
p {
    text-transform: uppercase;
    letter-spacing: 0.05em;
}

.list-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 5px;
  padding-right: 20px;
  padding-left: 20px;
  justify-content: center;
  align-self: center;
  margin-left: 20%;
}

.discipline-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 5px;
  padding-right: 5px;
  padding-left: 5px;
  justify-content: center;
  align-self: center;
}

.info-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding-right: 10px;
  padding-left: 10px;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-left: 20%;
}

.container {
  display: flex;
}
/* .sidebar {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  background: linear-gradient(to right, #202C3D, #103059);
}

.sidebarlist {
  width: 100%;
  height: auto;
  padding: 0
}

.sidebarlist .row{
  width: 100%;
  height: 50px;
  border: 1px solid white;
  list-style-type: none;
  margin: 0%;
  display: flex;
  color: white;
  justify-content: center;
  align-items: center;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 14px;
  flex-direction: row;
}

.sidebarlist .row:hover{
  cursor: pointer;
  background: 	#151e23;
} */

/* .row #icon{
  height: 50;
  flex: 30%;
  display: grid;
  place-items: center;
  justify-content: center;
}

.row #title{
  flex: 70%
} */

.score-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: center;
  align-items: center;
  margin-left: 20%;
}

.load-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.update-container {
  justify-content: right;
  align-items: right;
  height: 95vh; /* Set a fixed height */
  overflow: auto;
}

.activity-button{
  border: 0px solid #000000;
  background: linear-gradient(to right, #e97e79, #ee291f);
  justify-content: center;
  color: white;
  transform: scale(1);
  transition: transform 0.2s ease-in-out;
}
.activity-button:hover,
.activity-button:active {
  transform: scale(0.9);
}
.activity-button-green{
  border: 0px solid #000000;
  background: linear-gradient(to right, #53eb53, #2ea93f);
  justify-content: center;
  color: white;
  transform: scale(1);
  transition: transform 0.2s ease-in-out;
}
.activity-button-green:hover {
  background:linear-gradient(to right, #53eb53, #2ea93f);
}
.activity-button-green:active {
  transform: scale(0.9);
}

.activity-button-orange{
  border: 0px solid #000000;
  background:linear-gradient(to right, #f0cf7d, #ffc93c);
  justify-content: center;
  transform: scale(1);
  transition: transform 0.2s ease-in-out;
}
.activity-button-orange:hover,
.activity-button-orange:active {
  transform: scale(0.9);
}

.center-button {
  text-align: center;
  justify-content: center;
  padding-bottom: 20px;
}

/* @media (max-width: 768px) {
  .list-container {
    grid-template-columns: repeat(1, 1fr);
  }
} */

@media (min-width: 369px) and (max-width: 1024px) {
  .list-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 1025px) {
  .list-container {
    grid-template-columns: repeat(4, 1fr);
  }
  .sidebar {
    width: 20%;
  }
}


.hr {
  display: block;
  position: relative;
  padding: 0;
  margin: 20px;
  border: none;
  height: 3px;
  background: #000000;
}

.hr span {
  display: block;
  position: absolute;
  top: -0.5px;
  left: 0;
  width: 100%;
  height: 1px;
  background: linear-gradient(to right, transparent, #ccc, transparent);
}

.header-main-label {
  font-family: 'Poppins', sans-serif;
  font-size: 24px;
  font-weight: 600;
  color: #000000;
  text-transform: none;
  text-align: center;
  margin-top: 20px;
}

.capture-animation {
  animation-name: camera-capture;
  animation-duration: 0.2s;
}

@keyframes camera-capture {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}

.result {
  position: fixed;
  top: 0;
  left: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  background-color: rgba(0,0,0,0.9);
  transition: 0.4s;
}

.result.photoTaken {
left: 0;
}

video{
  width: 100%;
  max-width: 100%;
  height: auto;
}

.top-right {
  position: absolute;
  top: 0;
  right: 0;
}

.Good-Discipline {
  color: #2ea93f
}

.Bad-Discipline {
  color: #FF4136
}

.box-container {
  border: 2px solid #2ea93f;
  background-color: transparent;
  text-align: center;
  border-radius: 10px;
  width: 185px;
  height: 50px;
  bottom: 5px;
  justify-self: center;
  align-self: center;
  margin-left: 10px;
}

.time-label {
  font-family: 'Poppins', sans-serif;
  font-size: 12px;
  font-style: italic;
  font-weight: 600;
  color: #5c5454;
  text-transform: none;
  margin-top: 2px;
  margin-bottom: 10px;
}
.posted-time-label {
  font-family: 'Poppins', sans-serif;
  font-size: 12px;
  font-style: italic;
  font-weight: 600;
  color: #5c5454;
  text-transform: none;
  margin-left: 20px;
  margin-top: 2px;
  margin-bottom: 10px;
}
.tooltip {
  position: absolute;
  right: 40px;
  top: 60px;
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  padding: 8px;
  border-radius: 10px;
  font-size: 14px;
}

.unary-button {
  display: flex;
  border: 0px solid;
  background: linear-gradient(135deg, rgb(85, 83, 83), black);
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px 10px;
  border-radius: 10px;
  width: fit-content;
  margin-left:5px;
  margin-right:5px;
  height: fit-content;
  transform: scale(1);
  transition: transform 0.2s ease-in-out;
}

.unary-button:hover {
  background: linear-gradient(135deg, rgb(85, 83, 83), black);
  transition: 0.5s;
}

.unary-button svg {
  color: #000; /* Change to black */
}
.unary-button[name='water'] {
  background: linear-gradient(135deg, rgb(85, 83, 83), black);
}
.unary-button[name='water']:hover {
  background: linear-gradient(135deg, rgb(85, 83, 83), black);
  transition: 0.5s;
}

.unary-button[name='water'] svg {
  color: #000; /* Change to black */
}

.modal, .overlay {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  z-index: 9999; /* Set a higher z-index value */
  justify-content: center;
}

.overlay {
  background: rgba(49,49,49,0.8);
}
.modal-content {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: 1.4;
  background: linear-gradient(to right, rgb(196, 192, 192), rgb(199, 190, 190));
  padding: 14px 28px;
  border-radius: 10px;
  width: auto;
  min-width: 80%;
  z-index: 9999; /* Set a higher z-index value */
  justify-self: center;
}
.graph-content {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: 1.4;
  background:lightgray;
  padding: 14px 28px;
  border-radius: 10px;
  width: auto;
  min-width: 80%;
  z-index: 9999; /* Set a higher z-index value */
  justify-self: center;
}
@media (min-width: 769px) {
  .graph-content {
    padding-top: 160px; /* Apply padding only on system view (min-width: 769px) */
  }
}
.circle-background {
  fill:none;
  stroke: #ddd
}

.circle-progress {
  fill: none;
  stroke-linecap: round;
  stroke-linejoin: round;
  
  animation: progressBarAnimation 1s ease-in-out forwards;
}

.circle-text {
  font-size: 3rem;
  font-weight: bold;
}


.circle-svg {
  width: 30vw;
}
.afresh-container {
  display: flex;
  flex: 2;
  flex-direction: column;
  background: linear-gradient(135deg, #7fe9ad, #3aaf70);
  padding-left: 5px;
  padding-right: 5px;
  width: fit-content;
  justify-content: center;
  color: white;
  margin-right: 30px;
  text-align: center;
  align-self: flex-end;
  border-radius: 15px;
  height: fit-content;
}

.water-afresh-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 5px;
  padding-right: 20px;
  padding-left: 20px;
  justify-content: space-around;
}

.water-container {
  display: flex;
  flex: 2;
  flex-direction: column;
  background: linear-gradient(135deg, rgb(133, 189, 235), rgb(77, 164, 235));
  padding-left: 5px;
  padding-right: 5px;
  width: fit-content;
  height: fit-content;
  justify-content: center;
  justify-self: flex-end;
  color: white;
  margin-left: 30px;
  text-align: center;
  border-radius: 15px;
}
.tabs-container {
  display: flex;
  width: 100%;
}

.tab-button {
  flex-grow: 1;
  width: 50%;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 16px;
  padding: 10px 0;
  background-color: #f8f8f8;
  border-radius: 0px;
  color: green;
}

.active-tab {
  background-color: #fff;
  color: darkgreen;
  border-bottom: 3px solid darkgreen;
}

.tab-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 40px); /* Adjust based on your layout */
}

.content {
  width: 100%;
  text-align: center;
  padding: 20px;
}

.update-form {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  justify-content: center;
  align-items: center;
}

/* styles.css (or any appropriate CSS file) */

/* styles.css (or any appropriate CSS file) */

.bubble-list {
list-style-type: none;
display: flex;
flex-wrap: wrap;
padding: 0;
margin: 0;
max-width: 100%; /* Limit to screen width */
}

.bubble-list-item {
background-color: #e0e0e0;
border-radius: 20px;
padding: 5px 10px;
margin: 5px;
display: flex;
align-items: center;
white-space: nowrap; /* Prevent text wrapping */
}

.bubble-list-item button {
margin-left: 10px;
border: none;
background-color: transparent;
color: #ff0000;
cursor: pointer;
}

.multi-select-list {
max-height: 200px;
max-width: 320px;
align-self: center;
justify-self: center;
margin: 10px;
}
/* Set the border color for the multiselect dropdown */
.multi-select-list .multiselect-container {
color: #ff0000; /* Replace with your desired border color */
}

.bubble-list {
list-style-type: none;
padding: 0;
display: flex;
align-content: center;
justify-content: center;
flex-wrap: wrap;
}

.bubble {
background-color: #87CEEB;
color: #fff;
border-radius: 20px;
padding: 5px 10px;
margin: 5px;
display: flex;
align-items: center;
}

.cancel-button {
background-color: white;
border: none;
color: #87CEEB;
border-radius: 50%;
cursor: pointer;
width: fit-content;
height: fit-content;
margin-left: 5px;
font-size: 10px;
font-weight: bold;
}

.centered-container {
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
height: fit-content; /* This centers the container vertically */
}

.error-message {
color: red; /* Text color for the error message */
font-size: 14px; /* Font size for the error message */
margin-top: 10px; /* Optional margin to create space below the input */
text-transform: none;
}

/* @keyframes blink {
80% {
  opacity: 1;
  color: #000000;
}
90% {
  opacity: 0;
}
100% {
  opacity: 1;
  color: #ffffff;
}
} */

.weight-info {
font-size: 11px; /* Adjust font size responsively */
font-weight: bold;
padding: 0;
white-space: nowrap; /* Allow text to wrap */
word-break: normal;  /* Break words that exceed the container width */
max-width: 100%; /* Ensure the text doesn't overflow its container */
/* background-color: #19960083;   */
/* animation: blink 5s infinite; */
}

.highlight {
background: linear-gradient(to right, rgb(139, 205, 246),#057e96); /* Gradient background */
color: #FFFF; /* Black text for contrast */
font-weight: bold;
font-size: 14px;
padding: 0.4em 0.6em; /* Some padding to give it space */
border-radius: 9px; /* Rounded corners */
box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Subtle shadow */
transition: background 0.3s ease; /* Smooth transition for hover effect */
margin-right: 10px;
}

/*  */