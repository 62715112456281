.custom-button {
  width: 100px;
  margin-left: 10px;
  font-size: 12px;
  height: 35px;
  background-color: transparent;
  border: 1px solid #4caf50;
  color: #4caf50;
  outline: none;
  cursor: pointer;
  transition: background-color 0.3s, box-shadow 0.3s; /* Transition for both background color and box shadow */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Shadow effect */
}

.custom-button:hover {
  background-color: #4caf50;
  color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Shadow effect on hover */
}

.new-user-form {
  max-width: 400px;
  margin-top: 0; /* Set margin top to 0 */
  margin-right: auto; /* Center horizontally */
  margin-bottom: 40px; /* Margin at the bottom */
  margin-left: auto; /* Center horizontally */
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  font-family: 'Poppins', sans-serif;
  
}

.new-user-form h1 {
  text-align: center;
  color: #02aa83;
  margin-bottom: 20px;
}

.new-user-form form {
  display: flex;
  flex-direction: column;
  
}

.p-float-label {
  margin-bottom: 25px;
  
}

.p-float-label label {
  font-size: 13px;
  color: #2a967cde;
}

.p-inputtext {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  box-sizing: border-box; /* Make sure padding doesn't affect width */
}


.p-button {
  background-color: #02aa83;
  color: white;
  border: none;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  transition-duration: 0.4s;
  cursor: pointer;
  border-radius: 4px;
}

.p-button:hover {
  background-color: #038867; /* Darker shade for hover effect */
  color: white;
}

/* Responsive adjustments */
@media (max-width: 600px) {
  .new-user-form {
    margin-top: 0; 
    margin-right: 30px ; 
    margin-bottom: 40px; 
    margin-left: 20px ; 
    padding: 20px;
  }
}

.error {
  color: rgba(255, 0, 0, 0.815); /* Set the color of the error text to red */
  margin-left: 11px;
}
